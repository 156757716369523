/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.status {
  margin-top: 16px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.status.success {
  background-color: #28a745;
  color: white;
}

.status.error {
  background-color: #dc3545;
  color: white;
}

.home-sec {
  height: 100%;
}
